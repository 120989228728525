import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { STAR1Locales } from '../utils/internationalization/localeHelper';

export const useStar2 = () => {
  const { locale } = useRouter();

  const [isStar2, setIsStar2] = useState(locale && !STAR1Locales.includes(locale));

  useEffect(() => {
    setIsStar2(locale && !STAR1Locales.includes(locale));
  }, [locale]);

  return { isStar2 };
};
