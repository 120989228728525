import { forwardRef } from 'react';
import type { RefObject } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { NeedHelpSectionCheckout } from '..';
import { useCartContext } from '../../../../context/cartContext';
import { useCheckoutConfirmationContext } from '../../../../context/checkoutConfirmationContext';
import { useNeedHelpContext } from '../../../../context/needHelpContext';
import { useStar2 } from '../../../../hooks/useStar2';
import { CallCenter, WhatsApp } from '../../../../icons';
import { LinkPartial } from '../../../../partials/link/LinkPartial';
import { BagID, Contacts, IconContainer } from './NeedHelpContent.styles';

// eslint-disable-next-line react/function-component-definition
export const NeedHelpContent = forwardRef(
  ({ className, orderNumber = undefined }: { className?: string; orderNumber?: string }, ref) => {
    const { t } = useTranslation(['lib-global-common']);
    const { miniCart } = useCartContext();
    const { id } = { ...miniCart };
    const { isConfirmationPage, orderData } = useCheckoutConfirmationContext();
    const { needHelpData } = useNeedHelpContext();
    const { id: orderedCartId } = { ...orderData?.getOrderedCart };
    const bagId = orderNumber ?? (isConfirmationPage ? orderedCartId : id);
    const textPrefill = encodeURIComponent(t('cart.need.help.text.prefill', { bagId }));
    const { isStar2 } = useStar2();

    return (
      <div ref={ref as RefObject<HTMLDivElement>} className={className}>
        <p>
          <Trans
            i18nKey="cart.need.help.body"
            t={t}
            values={{
              bagId,
            }}
          >
            Contact us with your bag ID <BagID data-testid="cartId">(bagId)</BagID> and we can answer any questions:
          </Trans>
        </p>
        {isStar2 ? (
          <NeedHelpSectionCheckout
            emailLink={needHelpData?.emailLink}
            phoneLink={needHelpData?.phoneLink}
            contactUsLink={needHelpData?.contactUsLink}
            showContactUsLink={needHelpData?.showContactUsLink}
          />
        ) : (
          <Contacts>
            {t('cart.need.help.whatsapp.number', { defaultValue: false }) ? (
              <li>
                <a
                  href={`https://wa.me/${t('cart.need.help.whatsapp.number')}/?text=${textPrefill}`}
                  target="_blank"
                  rel="noreferrer"
                  data-testid="whatsappLink"
                >
                  <IconContainer>
                    <WhatsApp />
                  </IconContainer>
                  {t('cart.need.help.whatsapp')}
                </a>
              </li>
            ) : null}
            <li>
              <LinkPartial internalLink="/contact-us">
                <IconContainer>
                  <CallCenter />
                </IconContainer>
                {t('cart.need.help.contactUs')}
              </LinkPartial>
            </li>
          </Contacts>
        )}
      </div>
    );
  },
);
