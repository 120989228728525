import type {
  ContactUsLinkWithCopy,
  EmailLinkWithCopy,
  NeedHelpSectionCheckout as NeedHelpSectionCheckoutSchema,
  PhoneNumberWithCopy,
  ShowHideContactUsLink,
} from '@amplience/content-types/typings/c-need-help-section-checkout';
import { Mail, Telephone } from '../../../../icons';
import { LinkPartial } from '../../../../partials';
import { getLocalizedValue } from '../../../../utils';
import { Contacts, IconContainer } from './NeedHelpSectionCheckout.styles';

export type NeedHelpSectionCheckoutProps = NeedHelpSectionCheckoutSchema;

export function NeedHelpSectionCheckout({
  phoneLink,
  emailLink,
  contactUsLink,
  showContactUsLink,
}: {
  phoneLink?: PhoneNumberWithCopy;
  emailLink?: EmailLinkWithCopy;
  contactUsLink?: ContactUsLinkWithCopy;
  showContactUsLink?: ShowHideContactUsLink;
}) {
  return (
    <div>
      <Contacts>
        {showContactUsLink && contactUsLink?.link && contactUsLink?.copy ? (
          <li>
            <LinkPartial {...contactUsLink.link}>{getLocalizedValue(contactUsLink?.copy)}</LinkPartial>
          </li>
        ) : (
          <>
            {phoneLink?.phoneNumber && phoneLink?.copy && (
              <li>
                <a href={`tel:${phoneLink?.phoneNumber}`}>
                  <IconContainer>
                    <Telephone />
                  </IconContainer>
                  {getLocalizedValue(phoneLink?.copy)}
                </a>
              </li>
            )}
            {emailLink?.emailAddress && emailLink?.copy && (
              <li>
                <a
                  href={`mailto:${emailLink?.emailAddress}?body=${getLocalizedValue(emailLink?.prefilledText)}`}
                  data-testid="emailLink"
                >
                  <IconContainer>
                    <Mail />
                  </IconContainer>
                  {getLocalizedValue(emailLink?.copy)}
                </a>
              </li>
            )}
          </>
        )}
      </Contacts>
    </div>
  );
}
