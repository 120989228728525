import { useTranslation } from 'next-i18next';
import { NeedHelpContent } from '..';
import { DialogContent, DialogOverlay, DialogPortal, DialogRoot } from '../../../../baseComponents/dialog';
import { useCheckoutConfirmationContext } from '../../../../context/checkoutConfirmationContext';
import { Button, ContentWrapper, Label } from './NeedHelpModal.styles';

export function NeedHelpModal({ isCartEmpty, className }: { isCartEmpty: boolean; className?: string }) {
  const { t } = useTranslation(['lib-global-common']);
  const { isConfirmationPage } = useCheckoutConfirmationContext();

  if (isCartEmpty && !isConfirmationPage) return null;

  return (
    <DialogRoot>
      {/* Expanded is undefined to remove this attribute from rendering due to raised a11y issue */}
      <Button className={className} type="button" data-testid="cartNeedHelpButton" aria-expanded={undefined}>
        {t('cart.need.help')}
      </Button>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent data-testid="needHelpDialog">
          <Label>{t('cart.need.help')}</Label>
          <ContentWrapper asChild>
            <NeedHelpContent />
          </ContentWrapper>
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
}
