import styled from 'styled-components';
import { DialogDescription, DialogTitle, DialogTrigger } from '../../../../baseComponents/dialog';
import { TypographyStyles, colours, focusOutline, spacing, zNeedHelpButton } from '../../../../stylings';

export const Button = styled(DialogTrigger)`
  ${TypographyStyles.Button.MontserratSemiBold}
  background-color: ${colours.BLACK};
  border: 0;
  border-radius: 4px 4px 0px 0px;
  color: ${colours.WHITE};
  height: 44px;
  padding: ${spacing.XXXS} ${spacing.XS};
  position: absolute;
  right: 400px;
  top: 50vh;
  transform: translateX(calc(50% - 22px)) rotate(-90deg);
  width: max-content;
  z-index: ${zNeedHelpButton};

  .cart-page & {
    right: 100%;
    top: 250px;
  }

  &:focus-visible {
    ${focusOutline}
  }
`;

export const Label = styled(DialogTitle)`
  ${TypographyStyles.Headings.H5}
  display: block;
`;

export const ContentWrapper = styled(DialogDescription)`
  ${TypographyStyles.Body.Small.Regular}
`;
